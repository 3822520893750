<template>
    <el-dialog
            title="关联元数据"
            :visible.sync="dialogVisible"
            width="1000px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            :before-close="handleClose">
        <el-form size="small" :inline="true" class="query-form magBottom" ref="searchForm" :model="searchForm"
                 label-width="150px">
            <el-form-item prop="resourceName" label="元数据模板名称：">
                <el-input v-model.trim="searchForm.resourceName" maxlength="50" placeholder="请输入元数据模板名称"
                          clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="getDataList()" size="small">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()" size="small">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table
                :data="dataList"
                size="small"
                v-loading="loading"
                ref="multipleTable"
                height="300px"
                class="table magBottom"
                :row-key="'fieldName'"
                :header-cell-style="{background:'#F9DFDF'}"
                @selection-change="selectionChangeHandle"
        >
            <el-table-column :reserve-selection="true" type="selection" :selectable="canBeSelect" width="50"/>
            <el-table-column label="排序" type="index" width="50"></el-table-column>
            <el-table-column prop="id" label="元数据编号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="fieldName" label="元数据名" show-overflow-tooltip></el-table-column>
            <el-table-column prop="valType" label="元数据类型" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{
                    scope.row.valType == 0
                        ? "字符串"
                        : scope.row.valType == 1
                            ? "数字"
                            : scope.row.valType == 2
                                ? "时间"
                                : scope.row.valType == 3
                                    ? "日期"
                                    : ""
                    }}
                </template>
            </el-table-column>

            <el-table-column prop="limitLength" label="数据限制长度" show-overflow-tooltip></el-table-column>
        </el-table>
        <div class="text_center">
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>
        <div class="text_center" style="margin-top: 15px">
            <el-button @click="batchAssociation()"
                       size="small">批量关联
            </el-button>
            <el-button @click="handleClose()"
                       size="small">关闭
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "correlationData",
    data() {
        return {
            dialogVisible: false,
            selectData: {},
            searchForm: {
                resourceName: '',
            },

            dataListSelect: [],
            loading: false,
            dataList: [],

            pageNo2: 0,
            current: 1,
            size: 10,
            total: 0,
        }
    },

    methods: {
        init(row) {
            this.selectData = row
            this.dialogVisible = true
            this.$nextTick(() => {
                this.searchForm.resourceName = ''
                this.getDataList(1, 1)
            })
        },

        reset() {
            this.searchForm.resourceName = ''
            this.getDataList(1)
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', 1);
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        getDataList(num, dividePage) {
            if (num) {
                this.current = 1
            }
            if (dividePage) {
                this.$refs.multipleTable.clearSelection()
            }
            this.loading = true
            this.$axios(this.api.dyyg.originalGetDesc, {
                current: this.current,
                size: this.size,
                ...this.searchForm,
            }, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.current > 1) {
                        this.current--;
                        this.search()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        // 设置当前行是否禁用【可以选择】 参数row 传入当前行数据
        canBeSelect(row) {
            let reapet = this.selectData.ids.filter(item => {
                return item == row.fieldName
            })
            if (!reapet.length) {
                return true; // 返回true 可以选择
            } else {
                return false; // 返回false 禁止选择
            }
        },

        //批量关联
        batchAssociation() {
            if (!this.dataListSelect.length) {
                this.$message.warning('请至少选择一条数据')
                return
            }
            let ids = this.dataListSelect.map(item => {
                return item.id
            })
            this.$axios(this.api.dyyg.originalBatchAssociation, {
                id: this.selectData.id,
                ids: ids,
            }, 'post').then((res) => {
                if (res.status) {
                    this.$message.success('元数据添加成功')
                    this.$emit('refreshList')
                    this.handleClose()
                } else {
                    this.$message.error(res.msg);
                }
                this.loading = false
            })
        },

        handleClose() {
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>
.magBottom {
    margin-bottom: 20px;
}
</style>
